<div class="page-content p-1">
    <div class="card col-sm-12">
        <div class="card-body">
        <h3>Admin Users</h3>
        <table class="table">
            <tr>
                <td>#</td>
                <td>User</td>
                <td>Email</td>
                <td>@</td>
            </tr>
            <tr *ngFor="let item of Users">
                <td><i class="ti-user"></i></td>
                <td>{{item.Name}}</td>
                <td>{{item.Email}}</td>
                <td>
                    <button type="button" class="btn btn-primary btn-sm"><i class="ti-pencil"></i></button>
                </td>
            </tr>
        </table>
        </div>
    </div>
</div>
