import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import{ GlobalConstants } from '../../global-constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DataService } from '../../service/data.service';

import { Page } from '../../classes/page';
import { PageText } from '../../classes/page-text';

import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';


declare var $: any;

@Component({
  selector: 'app-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.css'],
  providers: [DataService]
})
export class PageDetailsComponent implements OnInit {
  
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private toastr: ToastrService
    ) { }

  summernoteConfig = GlobalConstants.summernoteConfig;
  languages = GlobalConstants.languages;
  uiText = GlobalConstants.uiText;
  crtLang;
  isLang;
  controller = "Pages/";
  table = "page_texts";
  PageId: string;
  Page: Page;
  crtPageText;
  isShow = true;
  
  ngOnInit(): void {
    this.crtLang = JSON.parse( localStorage.getItem('lang') );
    this.isLang = this.crtLang.code;
    this.Page = new Page();
    
    this.crtPageText = new PageText(this.PageId);
    this.PageId = this.route.snapshot.paramMap.get('id');
    this.resetPage();
    $('.modal').appendTo("body");
    
  }

  resetPage() :void {
    this.Page.setPage( this.PageId, null);
  }

  toggleView(){
    this.isShow = !this.isShow;
  }
  
  toggleLang(lang){
    this.isLang = lang;
    //console.log(this.isLang);
  }

  addPageText(): void {
    this.crtPageText = new PageText(this.PageId);
    this.crtPageText.PageId = this.Page.PageId;
    this.crtPageText.Content = '';
  }

  editPageText(i): void {
    this.crtPageText = new PageText(this.PageId);
    this.crtPageText.TextId = this.Page.PageTextList[i].TextId;
    this.crtPageText.Rank = this.Page.PageTextList[i].Rank;
    for(let lang of this.languages){
      if( this.Page.PageTextList[i].Langs[lang.code] ){
        this.crtPageText.Langs[lang.code] = this.Page.PageTextList[i].Langs[lang.code];
      }
    }
    //this.crtPageText.setData( this.Page.PageTextList[i] );
  }

  delPageText(TextId): void {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this section!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.dataService.getData( this.controller + 'DelPageDetails', {TextId: TextId} ).subscribe( res => {
          if(res == "ok"){
            Swal.fire(
              'Deleted!',
              'Page section was deleted and you will never get it back.',
              'success'
            );
            this.resetPage();
          }
        });
        
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Good! You changed your mind. :)',
          'error'
        )
      }
    })
   
  }

  onSubmit(){
    //console.log(this.crtPageText);
    this.dataService.getData( this.controller + 'SavePageDetails', { 
      PageId : this.PageId, 
      TextId : this.crtPageText.TextId,
      Rank : this.crtPageText.Rank, 
      Langs : this.crtPageText.Langs[this.isLang]
    } ).subscribe( res => {
    //this.dataService.execDataService( 'update', this.table, this.crtPageText ).subscribe( res => {
      if(res == "ok"){
        this.toastr.success("Page saved","Cool!");
        this.resetPage();
      } else { 
        console.log( res );
      }
    });
   $('#modalCloseButton').trigger('click');
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.Page.PageTextList, event.previousIndex, event.currentIndex);
    var ranks = [];
    var i = 0;
    for( let item of this.Page.PageTextList ){
      ranks.push( [ {'TextId': item.TextId , 'Rank': i } ] );
      i++;
    }
    this.dataService.getData( this.controller + 'PageTextRanks', {arrayData: ranks} ).subscribe( res => { 
      if(res == "ok"){
        this.toastr.success("Order saved","Cool!");
      }
    });
  }

}
