export class GlobalConstants {

    //public static url = "http://admin.euromd.ro";
    public static url = "http://admin.merck-medinfo.ro";

    public static languages = [
        { code: 'RO', name: 'Romana', flag: 'ro' },
        //{ code: 'EN', name: 'English', flag: 'gb' }
    ]

    public static bootstrapColors = [
        'primary', 'secondary', 'success', 'danger', 'info', 'warning'
    ];

    public static summernoteConfig = {
        placeholder: '',
        tabsize: 2,
        height: '200px',
        uploadImagePath: '/api/upload',
        toolbar: [
            ['misc', ['codeview', 'undo', 'redo']],
            ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
            ['fontsize', ['fontname', 'fontsize', 'forecolor', 'backcolor']],
            ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
            ['insert', ['table', 'picture', 'link', 'hr']]
        ],
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
    }

    public static uiText = {
        EN: {
            Localization: 'Localization',
            Pages: 'Pages',
            EditPage: 'Edit Page',
            PageTextSections: 'Page Text Sections',
            Products: 'Products',
            ProductsList: 'Products List',
            Categories: 'Categories',
            ProductCategories: 'Product Categories'
        },
        RO: {
            Localization: 'Localizare',
            Pages: 'Pagini',
            EditPage: 'Editare Pagina',
            PageTextSections: 'Sectiuni Text',
            Products: 'Produse',
            ProductsList: 'Lista Produse',
            Categories: 'Categorii',
            ProductCategories: 'Categorii Produse'
        }
    }

}