
import { Injectable } from '@angular/core';
import {Router,CanActivate,ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router';
import { BehaviorSubject } from 'rxjs'; 

@Injectable()
export class AuthguardService implements CanActivate {
 
  constructor(private router:Router) { }
  
  private loggedIn = new BehaviorSubject<boolean>(false);
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if ( localStorage.getItem('currentUser') && localStorage.getItem('token')  ) {
        //console.log("LOGGED IN!");
        this.loggedIn.next(true);
        return true;
    }
    //console.log("NOT LOGGED IN! Redirecting");
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    this.loggedIn.next(false);
    return false;
  }
 
}