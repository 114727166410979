import { Injectable } from '@angular/core';
import{ GlobalConstants } from '../global-constants';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs';
//import { Page } from "../classes/page";
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';
import { map, take } from 'rxjs/operators';
import { ProductCategory } from '../classes/product-category';

@Injectable()
export class DataService {
  private url: string = GlobalConstants.url + '/DataServiceApi/';

  static instance: DataService;
  constructor(private http: Http) { 
    DataService.instance = this;
  }

  getData(controllerAction, params) {
    //params.token = localStorage.getItem('token');
    //console.log(params);
    
    return this.http
      .post(this.url + controllerAction + '.php', params).pipe(map(
        (res ) => {
          //console.log( res );
          //debugger;
          return res.json();
        },
        err => {
          console.log('Data connection error');
          return err;
        }
      )
      )
  }

  execDataService(action, table, data) {
    return this.http
      .post(this.url + 'DataServiceActions.php', { token : localStorage.getItem('token'), action : action, table : table, data : data }).pipe(map(
        (res ) => {
          //console.log( res );
          return res.json();
        },
        err => {
          console.log('Data connection error');
          return err;
        }
      ))
  }

  getCategories(){
    var categories = [];
    this.getData( "Products/Categories", null ).subscribe( res => { 
        
        //categories = res;
          
          var objectArray = Object.entries(res);
          objectArray.forEach(([key, value]) => {
            //categories.push( [ ['id', value['Id'] ], ['Name', value['Name'] ] ] );
            //debugger;
            categories.push( [ ['id', value['Id'] ], ['Langs', value['Langs'] ] ] );
          })
          
          
    });
    //debugger;
    return categories;
  }

  login(post): Observable<any> {
    //console.log(post);
    const getLoginUrl = this.url + 'Login.php';
    
    return this.http
      .post(getLoginUrl, { username : post['username'], password : post['password'] })
      .pipe(take(1),map(
      res => {
        //console.log( res );
        if ( res.json().status == true ) {
          localStorage.setItem('currentUser', JSON.stringify( res.json().userName) );
          localStorage.setItem('token', JSON.stringify( res.json().token) );
          //console.log( 'Current user: ' + localStorage.getItem('currentUser') );
        }
        return res.json();
      },
      err => {
        console.log( 'Login error: ' + err );
        return err;
      }
      )
      )
  }
 
  logout() {
    //console.log('Logging out!');
    localStorage.removeItem('currentUser');
  }
}