import { PageText } from "./page-text";
import { DataService } from "../service/data.service";
import{ GlobalConstants } from '../global-constants';

export class Page {
    Id: number;
    PageId: number;
    Rank;
    BootstrapBg: string;
    Name;
    PageTextList: PageText[];
    //PageTexts;
    Langs = new Array;

   //Test = {"PageId":"1","Rank":"0","BootstrapBg":"primary", "Langs" : [{ "EN": {"Name":"Home"}, "RO": {"Name":"Home"} }] }

    private dataService: DataService;
    private controller = "Pages/";

    constructor(){
        if(this.BootstrapBg == null){
            this.BootstrapBg = "info";
        }
        
        //this.Langs.push('EN','RO');
        var languages = GlobalConstants.languages;
        for(let lang of languages){
        this.Langs[ lang.code ] =  {
            NameId: '',
            Name: '',
            PageTextList: []
            }
        }
    }

    setPage(id: string, lang: string){
        DataService.instance.getData(this.controller + 'PageDetails', { id: id } ).subscribe( (res) => {
            //debugger;
            //assign parameters
            this.Id = this.PageId = res.Id;
            var languages = GlobalConstants.languages;
            for(let lang of languages){
                //debugger;
                this.Langs[ lang.code ] =  {
                    NameId: res.Langs[lang.code]?.Id,
                    Name: res.Langs[lang.code]?.Name,
                    }
            };
            this.PageTextList = res.PageTexts;
            //console.log( this.PageTextList );
            //this.PageTextList = res.PageTextList as PageText[];
            /*
            this.PageTextList?.sort(function(a, b) {
              return a.Rank - b.Rank;
            });
            */
            if(this.BootstrapBg == null){
                this.BootstrapBg = "info";
            }
            //end
          });
    }

}
