import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthguardService }  from './service/authguard.service';

import { PagesComponent } from './comp-pages/pages/pages.component';
import { PageDetailsComponent } from './comp-pages/page-details/page-details.component';
import { ProductsComponent } from './comp-products/products/products.component';
import { ProductDetailsComponent } from './comp-products/product-details/product-details.component';
import { ProductCategoriesComponent } from './comp-products/product-categories/product-categories.component';
import { ProductCategoryDetailsComponent } from './comp-products/product-category-details/product-category-details.component';

import { EmdUsersComponent } from './admin/emd-users/emdUsers/emd-users.component';

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: '', component: HomeComponent, canActivate: [AuthguardService] },
    { path: 'pages', component: PagesComponent, canActivate: [AuthguardService] },
    { path: 'page-details/:id', component: PageDetailsComponent, canActivate: [AuthguardService] },
    { path: 'index', component: HomeComponent, canActivate: [AuthguardService] },
    { path: 'products', component: ProductsComponent, canActivate: [AuthguardService] },
    { path: 'product-details/:id', component: ProductDetailsComponent, canActivate: [AuthguardService] },
    { path: 'product-categories', component: ProductCategoriesComponent, canActivate: [AuthguardService] },
    { path: 'product-category-details/:id', component: ProductCategoryDetailsComponent, canActivate: [AuthguardService] },
    { path: 'emd-users', component: EmdUsersComponent, canActivate: [AuthguardService] },
    // otherwise redirect to home
    { path: '**', redirectTo: '/login' }
];

export const routing = RouterModule.forRoot(appRoutes);

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: true, })],
  exports: [RouterModule]
})
export class AppRoutingModule { }