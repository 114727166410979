
import{ GlobalConstants } from '../global-constants';

export class PageText {
    PageId: string;
    TextId: string;
    Rank: number;
    Langs = new Array;

    constructor(PageId){

        this.PageId = PageId;
        this.TextId = '';
        this.Rank = 999;
        var languages = GlobalConstants.languages;
        for(let lang of languages){
        this.Langs[ lang.code ] =  {
            Id: '',
            Lang: lang.code,
            Title: '',
            Content: ''
            }
        }
    }
    /*
    setData(data: PageText) {
        this.Id = data.Id;
        this.PageId = data.PageId;
        this.Title = data.Title;
        this.Content = data.Content;
        this.Rank = data.Rank;
    }

    myData() {
        var myData = {
            Id: this.Id,
            PageId: this.PageId,
            Title: this.Title,
            Content: this.Content,
            Rank: this.Rank
        };
        return myData;
    }
    */
}
