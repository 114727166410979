import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';

import { Product } from '../../classes/product';

import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ProductCategory } from '../../classes/product-category';

import{ GlobalConstants } from '../../global-constants';
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  providers: [DataService]
})
export class ProductsComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService
    ) { }
  
    languages = GlobalConstants.languages;
    controller = "Products/";
    uiText = GlobalConstants.uiText;
  Products: Product[];
  crtProduct: Product;
  categories: ProductCategory[];
  isLang;

  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    //this.categories = this.dataService.getCategories();
    
    this.isLang = JSON.parse( localStorage.getItem('lang') ).code;
    this.uiText = GlobalConstants.uiText;
    this.dataService.getData(this.controller + 'Categories', null ).subscribe( res => { this.categories = res; });
    //console.log(this.categories);
    this.dtOptions = {
    };

    this.crtProduct = new Product;
    this.resetPage();
    $('.modal').appendTo("body");
    //$('.dataTable').DataTable();
  }

  resetPage(): void {
    this.SpinnerService.show();
    this.dataService.getData(this.controller + 'Products', {} ).subscribe( res => { 
      this.Products = res;
      this.SpinnerService.hide();
     });
    //this.dataService.execDataService( "select", "products", null ).subscribe( res => { this.Products = res; } );
  }

  toggleActive(item : Product){
    item.isActive = !item.isActive;
    var status = item.isActive == true ? 0 : 1;
    //debugger;
    this.dataService.execDataService( "update", "products", {Id: item.Id, isActive: status } ).subscribe()
  }

  toggleLang(lang){
    this.isLang = lang;
  }

  onSubmit(): void {
    //console.log(formData);
    var items = [];
    for(let lang of this.languages){
      items.push( [{'Id': this.crtProduct.Langs[ lang.code ].Id, 'Lang': lang.code, 'Name': this.crtProduct.Langs[ lang.code ].Name }] );
      //debugger;
    }

    //debugger;
    this.dataService.getData( "Products/AddProduct", { arrayData: items } ).subscribe( res => { 
      if(res == "ok"){
        this.toastr.success("Product Created","Cool!");
        $('#modalCloseButton').trigger('click');
        this.resetPage();
      }
    });

    /*
    this.dataService.getData( this.controller + 'SaveProductDetails', this.crtProduct ).subscribe( res => {
      if(res == "ok"){
        this.toastr.success("Product Added","Cool!");
        $('#modalCloseButton').trigger('click');
        this.resetPage();
      }
      //console.log(res);
    });
    */
  }

  getCategoryById(id){
    if( id != 0 ){
      return this.categories?.find(x => x.Id === id)?.Langs[ this.isLang ].Name;
    }
  }

}
