
import{ GlobalConstants } from '../global-constants';

export class Product {
    Id: number;
    Name: string;
    ShortDescription: string;
    Description: string;
    Category: number;
    isActive: boolean;
    Langs = new Array;

    constructor(){
        
        //this.Langs.push('EN','RO');
        var languages = GlobalConstants.languages;
        for(let lang of languages){
        this.Langs[ lang.code ] =  {
            Id: '',
            Lang: '',
            ProdId: '',
            Name: '',
            ShortDescription: '',
            Description: ''
            }
        }
    }

}
