<div class="page-content p-3">
  <div class="row">
    <div class="col-sm-6">
    <div class="card">
      <div class="card-header">
        <p class="card-title">Welcome
          <span class="ti-user float-right"></span>
        </p>
      </div>
        <div class="card-body">
          Current user: <strong>{{userName}}</strong>
        </div>
    </div>
    </div>
      <div class="col-sm-3">
      <div class="card">
          <div class="card-body">
              <h2><span class="ti-layout"></span> <br> {{uiText[isLang].Pages}}: {{pages}}</h2>
          </div>
      </div>
      </div>
      <div class="col-sm-3">
      <div class="card">
        <div class="card-body">
          <h2><span class="ti-package"></span> <br> {{uiText[isLang].Products}}: {{products}}</h2>
        </div>
      </div>
      </div>
    </div>
    <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header">
          <p class="card-title">{{uiText[isLang].Localization}}
            <!--
            <span class="flag-icon flag-icon-{{isLang}} float-right"></span>
            -->
          </p>
        </div>
        <div class="card-body">
          <div *ngFor="let item of languages">
            <span class="flag-icon flag-icon-{{item.flag}}"></span> {{item.code}} - {{item.name}}
            <hr>
          </div>
        </div>
      </div>
      </div>
    </div>
</div>