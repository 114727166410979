<div class="center">
  <form [formGroup]="rForm" (ngSubmit)="addPost(rForm.value)" class="center-form text-center">
      <div class="col-12">
        <img src="/assets/pics/logo-short.png" width="100">
      </div>
      <div class="col-12 mt-3">
          <h4 class="mb-0 text-primary">Username:</h4>
          <input type="text" #usernameobj appAutofocus formControlName="username" class="form-control">
          <!--
          <div class="alert" *ngIf="!rForm.controls['username'].valid && rForm.controls['username'].touched">{{ usernameAlert }}</div>
          -->
      </div>
      <div class="col-12 mt-1">
        <h4 class="mb-0 text-primary">Password:</h4>
        <input type="password" formControlName="password" class="form-control">
        <!--
        <div class="alert" *ngIf="!rForm.controls['password'].valid && rForm.controls['password'].touched">{{ passwordAlert }}</div>
        -->
      </div>
      <div class="col-12 mt-3"> 
        <input type="submit" class="btn btn-primary form-control" value="Login" [disabled]="!rForm.valid">
        <div class="callout warning" *ngIf="loginError"> <h4 class="text-danger">{{ loginAlert }}</h4> </div>
      </div>

  </form>
</div>
  