import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';

import { ProductCategory } from '../../classes/product-category';

import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import{ GlobalConstants } from '../../global-constants';

declare var $: any;

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.css'],
  providers: [DataService, ProductCategory]
})
export class ProductCategoriesComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private toastr: ToastrService
    ) { }

    languages = GlobalConstants.languages;
    controller = "Products/";
    uiText = GlobalConstants.uiText;
  table = 'product_categories';
  categories: ProductCategory[];
  crtCategory = new ProductCategory();
  isLang;

  ngOnInit(): void {
    this.isLang = JSON.parse( localStorage.getItem('lang') ).code;
    this.resetPage();
    $('.modal').appendTo("body");
  }

  resetPage(){
    this.crtCategory = new ProductCategory();
    
    this.dataService.getData( this.controller + 'Categories', {} ).subscribe( (res : ProductCategory[] ) => { 
      this.categories = res;
      //debugger;
        this.categories.sort(function(a, b) {
          return a.Rank - b.Rank;
        });
     });
  }

  editItem(index){
    this.crtCategory = this.categories[index] as ProductCategory;
  }

  resetModal(){
    this.crtCategory = new ProductCategory();
    //this.modalTitle = "Add New Page";
  }

  //update category names
  onSubmit(): void {
    var items = [];
    for(let lang of this.languages){
      items.push( [{'Id': this.crtCategory.Langs[ lang.code ].Id, 'Lang': lang.code, 'Name': this.crtCategory.Langs[ lang.code ].Name }] );
      //debugger;
    }
    //debugger;
    if( this.crtCategory.Id ){
    //debugger;
    this.dataService.execDataService( 'update', "product_category_texts", { arrayData: items } ).subscribe( res => { 
        if(res == "ok"){
          this.toastr.success("Category saved","Cool!");
          $('#modalCloseButton').trigger('click');
          this.resetPage();
        }
        //console.log(res);
      });
    }
    else
    {
      //debugger;
      this.dataService.getData( "Products/AddCategory", { arrayData: items } ).subscribe( res => { 
        if(res == "ok"){
          this.toastr.success("Category Created","Cool!");
          $('#modalCloseButton').trigger('click');
          this.resetPage();
        }
      });
    }
  }

  delItem(id): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead!',
      cancelButtonText: 'Ouch, keep it!'
    }).then((result) => {
      if (result.value) {
        this.dataService.execDataService( 'delete', this.table, {Id:id} ).subscribe( res => {
          if(res == "ok"){
            Swal.fire(
              'Deleted!',
              'Item was deleted and you will never get it back.',
              'success'
            )
            this.resetPage();
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Good! You changed your mind. Hmmm! :)',
          'error'
        )
      }
    })
   
  }

  drop(event: CdkDragDrop<string[]>) {
    
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    var ranks = [];
    var i = 0;
    for( let item of this.categories ){
      ranks.push( [ {'Id': item.Id , 'Rank': i } ] );
      i++;
    }
    this.dataService.execDataService( 'update', this.table, {arrayData: ranks} ).subscribe( res => { 
      if(res == "ok"){
        this.toastr.success("Order saved","Cool!");
      }
    });

  }

toggleActive(item: ProductCategory){
    item.isActive = !item.isActive;
    var status = item.isActive == true ? 0 : 1;
    this.dataService.execDataService( "update", "product_categories", {Id: item.Id, isActive: status } ).subscribe();
}

  toggleLang(lang){
    this.isLang = lang;
  }

}
