import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../global-constants';

import { DataService } from '../service/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DataService]
})
export class HomeComponent implements OnInit {

  constructor(
    private dataService: DataService
    ) { }
  
  userName;
  isLang;
  languages = GlobalConstants.languages;
  uiText;

  pages;
  products;

  ngOnInit(): void {
    this.userName = JSON.parse( localStorage.getItem('currentUser') );
    this.isLang = JSON.parse( localStorage.getItem('lang') ).code;
    this.uiText = GlobalConstants.uiText;

    this.dataService.getData("Dashboard",null).subscribe(res=>{
      this.pages = res.Pages;
      this.products = res.Products;
    });
  }

}
