<div class="page-content p-3">
        
    <div class="float-right">
        <button class="btn" (click)="toggleView()" [disabled]="isShow"><i class="ti-layout-grid2"></i></button> 
        <button class="btn" (click)="toggleView()" [disabled]="!isShow"><i class="ti-view-list"></i></button> 
        <button class="btn" data-toggle="modal" data-target="#myModal" (click)="resetModal()"><i class="ti ti-plus"></i></button>
    </div>
    <h3>{{uiText[isLang].Pages}}
      <button class="btn" *ngFor="let lang of languages;" [disabled]="lang.code == isLang" (click)="toggleLang(lang.code)"> <span class="flag-icon flag-icon-{{lang.flag}}"></span> </button>
    </h3>
    <hr>
    <div *ngIf="isShow">
        <div cdkDropListGroup class="row">
            <div class="col example-container" *ngFor="let c of layoutColumns; let i = index">
              <div
                cdkDropList
                [cdkDropListData]="layoutColumns[i]"
                class="example-list"
                (cdkDropListDropped)="drop2($event)">
                <div class="example-box" *ngFor="let item of layoutColumns[i]" cdkDrag>
                  <div class="border-bottom">
                    <h5 class="badge badge-{{item.BootstrapBg}}"> {{ item.Langs[ isLang ]?.Name }} </h5>

                    <div class="float-right">
                      <a href="javascript:void(0)" 
                          *ngFor="let color of bootstrapColors" 
                          class="text-{{color}} ml-1"
                          (click)="updateBg(item, color)"
                          title="{{color}}"
                          >
                          <i class="ti-html5"></i>
                        </a>
                    </div>
                  </div>
                  <br>
                    <h5>PageId: {{ item.PageId }}</h5>
                    <h5>Rank: {{ item.Rank }}</h5>
                    <div><hr></div>
                    <a class="btn float-right" routerLink="/page-details/{{item.Id}}"><i class="ti ti-trash"></i></a>
                    <a class="btnr btnr4 btnr-sm" routerLink="/page-details/{{item.Id}}"><i class="ti ti-paint-roller"></i></a>
                    <a class="btnr btnr1 btnr-sm ml-2" data-toggle="modal" data-target="#myModal" href="javascript:void(0)" (click)="editPage(item.Id)"><i class="ti ti-pencil"></i></a>

                </div>
              </div>
            </div>
        </div>
    </div>
    
    <div class="card" *ngIf = "!isShow">
        <div class="card-body">
        <h4 class="card-title">Pages</h4>
        <table class="table">
            <tr>
                <th>#</th>
                <th>Page Id</th>
                <th>Name</th>
                <th style="width:150px">@</th>
            </tr>
            <tr *ngFor='let item of Pages;let i = index'>
                <td>{{i + 1}}</td>
                <td>{{ item.PageId }}</td>
                <td><h5 class="badge badge-{{item.BootstrapBg}}">{{ item.Langs[ isLang ]?.Name }}</h5></td>
                <td>
                    <a class="btnr btnr4 btnr-sm" routerLink="/page-details/{{item.Id}}"><i class="ti ti-paint-roller"></i></a>
                    <a class="btnr btnr1 btnr-sm ml-2" data-toggle="modal" data-target="#myModal" href="javascript:void(0)" (click)="editPage(item.Id)"><i class="ti ti-pencil"></i></a>
                    <a class="btn float-right" routerLink="/page-details/{{item.Id}}"><i class="ti ti-trash"></i></a>
                </td>
            </tr>
        </table>
        </div>
    </div>
</div>
<!--Modal-->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">{{modalTitle}}</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="modalCloseButton">
            <span aria-hidden="true" class="ti-close"></span>
          </button>
        </div>
        <form (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="modal-body">
          <div class="form-group">
            <input type="hidden" [(ngModel)]="newPage.Id" [ngModelOptions]="{standalone: true}">
            <div *ngFor="let lang of languages;">
            <label><span class="flag-icon flag-icon-{{lang.flag}}"></span> Name:</label>
              <input type="text" class="form-control" [(ngModel)]="newPage.Langs[ lang.code ].Name" [ngModelOptions]="{standalone: true}">
              <hr>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal"><span class="ti-na"></span> Close</button>
          <button type="submit" class="btn btn-primary"><span class="ti-save"></span> Save</button>
        </div>
      </form>
      </div>
    </div>
  </div>




