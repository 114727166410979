<div class="page-content p-1">

  <div class="float-right">
    <button class="btn" data-toggle="modal" data-target="#myModal"><i class="ti ti-plus"></i></button>
</div>
<h3>{{uiText[isLang].ProductCategories}}
  <button class="btn" *ngFor="let lang of languages;" [disabled]="lang.code == isLang" (click)="toggleLang(lang.code)"> <span class="flag-icon flag-icon-{{lang.flag}}"></span> </button>
</h3>
        <div class="card-body">
        
        <h5 class="card-title">
          Product Categories:
        </h5>
        <div class="clearfix"></div>
          <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let item of categories; let i = index" cdkDrag>
              <div>    
              <span class="ti-arrows-vertical mr-1 text-muted"></span>
                  {{item.Langs[isLang]?.Name}}</div>
                <div class="float-right">
                  <button class="btn" data-toggle="modal" data-target="#myModal" (click)="editItem(i)"><i class="ti-pencil"></i></button>
                  <a class="btn mr-2" routerLink="/product-category-details/{{item.Id}}" ><i class="ti-paint-roller"></i></a>
                  <mat-slide-toggle 
                    color="primary"
                    [checked]="item.isActive" (click)="toggleActive(item)"></mat-slide-toggle>
                  
                  <button class="btn" (click)="delItem(item.Id)"><i class="ti-trash"></i></button>
                </div>
            </div>
          </div>
        </div>
</div>
<!--Modal-->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Edit Category</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="modalCloseButton">
            <span aria-hidden="true" class="ti-close"></span>
          </button>
        </div>
        <form (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form-group">
            <input type="hidden" [(ngModel)]="crtCategory.Id" [ngModelOptions]="{standalone: true}">
            <div *ngFor="let lang of languages;">
            <label><span class="flag-icon flag-icon-{{lang.flag}}"></span> Category Name:</label>
            <input type="text" class="form-control" [(ngModel)]="crtCategory.Langs[ lang.code ].Name" [ngModelOptions]="{standalone: true}">
            <hr>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal"><span class="ti-na"></span> Close</button>
          <button type="submit" class="btn btn-primary"><span class="ti-save"></span> Save</button>
        </div>
      </form>
      </div>
    </div>
  </div>