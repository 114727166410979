<div class="page-content p-1">
    <div class="card col-sm-12">
    <div class="card-body">
    <div *ngIf="isShow">
            <h3>
            <a class="btn btn-default btn-lg" mat-list-item routerLink="/product-categories"><span class="ti-arrow-circle-left"></span></a>
            Category Details
            <button class="btn" *ngFor="let lang of languages;" [disabled]="lang.code == isLang" (click)="toggleLang(lang.code)"><span class="flag-icon flag-icon-{{lang.flag}}"></span></button>
            <button type="button" class="btn btn-danger btn-sm float-right" (click)="toggleView()"><span class="ti-pencil"></span> </button>
            </h3>
            <div class="row">
                <div class="col-sm-3">
                    <h3>
                        Category:
                        <strong>{{ProductCategory.Langs[isLang]?.Name}}</strong>
                    </h3>
                </div>
                <div class="col-sm-9">
                    <div class="jumbotron p-2">
                    <strong>Short Description:</strong>
                        <div [innerHTML]="ProductCategory.Langs[isLang]?.ShortDescription | safe : 'html'"></div>
                    </div>
                    <div class="card p-2 mt-2">
                        <strong>Full Description:</strong>
                        <div [innerHTML]="ProductCategory.Langs[isLang]?.Description | safe : 'html'"></div>
                    </div>
                </div>
            </div>
        <!--
        <div class="row">
            <div class="col-sm-12">
                <hr>
                <h4>Pictures
                    <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display:none;" />
                    <button type="submit" class="btn btn-warning btn-sm float-right" (click)="onUpload()"> <i class="ti-upload"></i> </button>
                </h4>
                <div *ngFor="let file of files">  
                    <mat-progress-bar [value]="file.progress"></mat-progress-bar>  
                    <span id="file-label"> </span>  
                </div> 
                <div class="row">
                    <div class="col-sm-1" *ngFor="let item of pictures">
                        <button type="button" class="btn float-right" (click)="deleteFile( item.Id )" style="position:relative;top:20px; left:15px;"><span class="ti-trash"></span></button>
                        <img src="http://admin.euromd.ro/DataServiceApi/uploads/{{item.file}}" class="img-thumbnail">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <hr>
                <h4>Documents
                    <input type="file" #docUpload id="docUpload" name="docUpload" style="display:none;" />
                    <button type="button" (click)="onDocUpload()" class="btn btn-info btn-sm float-right"><span class="ti-upload"></span> </button>
                </h4>
                <div *ngFor="let item of docs">
                    <div *ngIf="item.Lang == isLang" class="row">
                        <div class="col-md-1">
                            <span class="ti-file"></span>
                        </div>
                        <div class="col-md-4">
                            <p>{{item.Name}}</p>
                        </div>
                        <div class="col-md-4">
                            <a href="uploads/productDocs/{{ProductId}}/{{item.File}}" target="_blank">
                            {{item.OriginalFile}} 
                            </a>
                        </div>
                        <div class="col-md-3">
                            <a href="javascript:;" data-toggle="modal" data-target="#myModal" (click)="editDoc(item.Id, item.Name)">
                                <span class="ti-pencil"></span>
                            </a> 
                            &nbsp;
                            <a href="javascript:;" (click)="deleteDoc(item.Id)">
                                <span class="ti-trash"></span>
                            </a>
                        </div>
                        <div class="col-md-12">
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        -->
    </div>
    <div *ngIf="!isShow">
        <button type="button" class="btn btn-lg" (click)="toggleView()"><span class="ti-arrow-circle-left"></span> </button> Edit Description
        <button class="btn" *ngFor="let lang of languages;" [disabled]="lang.code == isLang" (click)="toggleLang(lang.code)"> <span class="flag-icon flag-icon-{{lang.flag}}"></span> </button>
        <form (ngSubmit)="submitCategory()" #form="ngForm">
            <div class="form-group">
                <input type="hidden" [(ngModel)]="ProductCategory.Id" [ngModelOptions]="{standalone: true}" readonly>
                <input type="hidden" [(ngModel)]="ProductCategory.Langs[isLang].Id" [ngModelOptions]="{standalone: true}" readonly>
                
                <div class="row">
                    <div class="col-sm-6">
                    <label>Name:</label>
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="ProductCategory.Langs[isLang].Name" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <label>Short Description:</label>
                <textarea [ngxSummernote]="summernoteConfig" [(ngModel)]="ProductCategory.Langs[isLang].ShortDescription" [ngModelOptions]="{standalone: true}"></textarea>
                <label>Full Description:</label>
                <textarea [ngxSummernote]="summernoteConfig" [(ngModel)]="ProductCategory.Langs[isLang].Description" [ngModelOptions]="{standalone: true}"></textarea>
            </div>
            <button type="submit" class="btn btn-primary float-right"><span class="ti-save"></span> Save</button>
        </form>
    </div>
    </div>
    </div>
  </div>

  <!--Modal-->
<!--
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form (ngSubmit)="changeDocName()" #form="ngForm">
            <div class="modal-header">
            <h5 class="modal-title">Upload Document</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="modalCloseButton">
                <span aria-hidden="true" class="ti-close"></span>
            </button>
            </div>
            <div class="modal-body">
            <div class="form-group">
                <input type="hidden" [(ngModel)]="crtDoc['Id']" [ngModelOptions]="{standalone: true}" >
                <label>
                <span class="flag-icon flag-icon-{{crtDoc['Lang']}}"></span> Document Title:
                </label>
                <input type="text" [(ngModel)]="crtDoc['Name']" [ngModelOptions]="{standalone: true}" class="form-control" autocomplete="off">
            </div>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><span class="ti-na"></span> Close</button>
            <button type="submit" class="btn btn-primary"><span class="ti-save"></span> Save</button>
            </div>
        </form>
      </div>
    </div>
</div>
-->