import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import{ GlobalConstants } from '../../global-constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DataService } from '../../service/data.service';

import { Product } from '../../classes/product';

import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ProductCategory } from '../../classes/product-category';

import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';  
import { catchError, map } from 'rxjs/operators';  
import { UploadService } from  '../../service/upload.service';


declare var $: any;

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
  providers: [DataService]
})
export class ProductDetailsComponent implements OnInit {
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef; 
  @ViewChild("docUpload", {static: false}) docUpload: ElementRef;
  files  = [];

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private toastr: ToastrService,
    private uploadService: UploadService) { }

    summernoteConfig = GlobalConstants.summernoteConfig;
    languages = GlobalConstants.languages;
    isLang;
    controller = "Products/";
    ProductId;
    Product: Product;
    isShow = true;
    categories: ProductCategory[];
    pictures = [];
    docs = [];

    crtDoc = [];

    //formFile;

  ngOnInit(): void {
    this.isLang = JSON.parse( localStorage.getItem('lang') ).code;
    this.ProductId = this.route.snapshot.paramMap.get('id');
    //this.categories = this.dataService.getCategories();
    this.dataService.getData(this.controller + 'Categories', null ).subscribe( res => { this.categories = res; });
    //debugger;
    $('.modal').appendTo("body");
    this.resetPage();
  }

resetPage(){
  //debugger;
  //this.dataService.execDataService( "select", "product_categories", null ).subscribe( res => { this.categories = res; } );
  this.getText();
  this.getPictures();
  this.getDocs();
  this.crtDoc['Id'] = null;
  this.crtDoc['Name'] = '';

}

getText(){
  this.Product = new Product;
  this.dataService.getData(this.controller + 'ProductDetails', { id: this.ProductId } ).subscribe( res => {
    //this.Product = res;
    this.Product.Id = res.Id;
    this.Product.Category = res.Category;

    var languages = GlobalConstants.languages;
    for(let lang of languages){
        if( res.Langs[ lang.code ] ){
          this.Product.Langs[ lang.code ] =  {
              Id: res.Langs[lang.code].Id,
              ProdId: res.Langs[lang.code].ProdId,
              Lang: res.Langs[lang.code].Lang,
              Name: res.Langs[lang.code].Name,
              ShortDescription: res.Langs[lang.code].ShortDescription,
              Description: res.Langs[lang.code].Description
          }
        }
        else
        {
          this.Product.Langs[ lang.code ] =  {
            ProdId: this.ProductId.Id,
            Lang: lang.code,
            Name: '',
            ShortDescription: '',
            Description: ''
          }
        }
    };
  });
}

getPictures(){
  this.dataService.execDataService( "select", "product_pictures", { where : 'ProductId=' + this.ProductId } ).subscribe( res => { 
    this.pictures = res; 
  });
}

getDocs(){
  this.dataService.getData( this.controller + "ProductDocs", { id : this.ProductId } ).subscribe( res => { 
    this.docs = res;
  });
}

toggleView(){
  this.isShow = !this.isShow;
}

toggleLang(lang){
  this.isLang = lang;
}

submitProduct(){
  this.dataService.getData( this.controller + 'SaveProductDetails', {
      ProdId: this.Product.Id,
      Category : this.Product.Category,
      Langs : this.Product.Langs[this.isLang]
  } ).subscribe( res => {
    if(res == "ok"){
      this.toastr.success("Product saved","Cool!");
      this.toggleView();
    }
  });
}


onUpload() {  
  const fileUpload = this.fileUpload.nativeElement;
  fileUpload.onchange = () => {  
  for (let index = 0; index < fileUpload.files.length; index++)  
  {  
   const file = fileUpload.files[index];  
   this.files.push({ data: file, inProgress: false, progress: 0});  
  }  
    this.uploadFiles();
  };  
  fileUpload.click();  
}

private uploadFiles() {  
  this.fileUpload.nativeElement.value = '';  
  this.files.forEach(file => {
    var response = this.uploadFile(file);
  });
}

uploadFile(file) {  
  const formData = new FormData();  
  formData.append('file', file.data); 
  formData.append('ProductId', this.Product.Id.toString() );
  file.inProgress = true;  
  this.uploadService.upload(formData).pipe(  
    map(event => {  
      switch (event.type) {  
        case HttpEventType.UploadProgress:  
          file.progress = Math.round(event.loaded * 100 / event.total);  
          break;  
        case HttpEventType.Response:
          return event;
      }  
    }),  
    catchError((error: HttpErrorResponse) => {  
      file.inProgress = false;  
      return of(`${file.data.name} upload failed.` + event);
    })).subscribe((event: any) => { 
      if (typeof (event) === 'object') {  
        //console.log(event.body);
        this.getPictures();
      }  
    });  
}

deleteFile(id){
  this.dataService.getData( 'fileDelete', { id: id } ).subscribe( res => {
    if(res == "ok"){
      this.toastr.success("Picture erased","Cool!");
      this.getPictures();
    }
  });
}

onDocUpload() {
  const docUpload = this.docUpload.nativeElement;
  docUpload.onchange = () => {  
  for (let index = 0; index < docUpload.files.length; index++)  
  {  
   const file = docUpload.files[index]; 
   this.files.push({ data: file, inProgress: false, progress: 0});  
  }  
    this.uploadDocFiles();  
  };
  
  docUpload.click();  
}

private uploadDocFiles() {  
  this.fileUpload.nativeElement.value = '';  
  this.files.forEach(file => {
    var response = this.uploadDocFile(file);
  });
  this.files = [];
}

uploadDocFile(file){
  const formData = new FormData();  
  formData.append('file', file.data);
  formData.append('ProductId', this.Product.Id.toString() );
  formData.append('Lang', this.isLang);
  /*
  for(let lang of this.languages){
    formData.append('Name[' + lang.code + ']', this.docName[lang.code] );
  }
  */
  this.uploadService.uploadDoc(formData).pipe(  
    map(event => {  
      switch (event.type) {  
        case HttpEventType.UploadProgress: 
          break;  
        case HttpEventType.Response:
          return event;
      }  
    })).subscribe((event: any) => { 
      if (typeof (event) === 'object') {  
        //console.log(event.body);
        this.getDocs();

        //$('#modalCloseButton').trigger('click');
      }  
    }); 
}

editDoc(id, name){
  this.crtDoc['Id'] = id;
  this.crtDoc['Name'] = name;
  this.crtDoc['Lang'] = this.languages.filter(x => x.code == this.isLang)[0].flag;
}

changeDocName(){
  this.dataService.execDataService('update','product_docs', { Id: this.crtDoc['Id'], Name: this.crtDoc['Name'] }).subscribe( res => {
    if(res == "ok"){
      this.toastr.success("Document Name Changed.","Cool!");
      this.getDocs();
      $('#modalCloseButton').trigger('click');
    }
  });
}

deleteDoc(id){
  var conf = confirm('Delete Document');
  if(conf){
    this.dataService.execDataService('delete','product_docs', { Id: id }).subscribe( res => {
      if(res == "ok"){
        this.toastr.success("Document Deleted.","Cool!");
        this.getDocs();
      }
    });
  }
}

/*
uploadDocFile(file){
  const formData = new FormData();  
  formData.append('file', file.data);
  formData.append('ProductId', this.Product.Id.toString() );
  for(let lang of this.languages){
    formData.append('Name[' + lang.code + ']', this.docName[lang.code] );
  }
  this.uploadService.uploadDoc(formData).pipe(  
    map(event => {  
      switch (event.type) {  
        case HttpEventType.UploadProgress: 
          break;  
        case HttpEventType.Response:
          return event;
      }  
    })).subscribe((event: any) => { 
      if (typeof (event) === 'object') {  
        //console.log(event.body);
        this.getDocs();
        $('#modalCloseButton').trigger('click');
      }  
    }); 
}
*/
}
