import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';

@Component({
  selector: 'app-emd-users',
  templateUrl: './emd-users.component.html',
  styleUrls: ['./emd-users.component.css'],
  providers: [DataService]
})
export class EmdUsersComponent implements OnInit {

  table = "admin_users";
  Users = [];
  constructor( private dataService: DataService ) { }

  ngOnInit(): void {
    this.dataService.execDataService( "select", this.table, null ).subscribe( res => { 
      this.Users = res;
    });
  }

}
