import { Injectable } from '@angular/core';
import{ GlobalConstants } from '../global-constants';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';  
import { map } from  'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  SERVER_URL: string = GlobalConstants.url + '/DataServiceApi/';
  constructor(
    private httpClient: HttpClient
    ) { }

public upload(formData) {
  return this.httpClient.post<any>(this.SERVER_URL + 'fileUpload.php', formData, {  
      reportProgress: true,
      observe: 'events'  
    });  
}

public uploadDoc(formData) {
  return this.httpClient.post<any>(this.SERVER_URL + 'docUpload.php', formData, {  
    reportProgress: true,
    observe: 'events'
  }); 
}

public upload2(formData) {
  console.log('upload2');
  return this.httpClient.post<any>(this.SERVER_URL + 'fileUpload.php', formData ).pipe(map(
    (res) => {
      return res.json();
    },
    err => {
      console.log('Data connection error');
      return err;
    }
  ));  
}

}
