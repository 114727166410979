<div class="page-content p-1">
  <h3>
    <a class="btn btn-default" mat-list-item routerLink="/pages" style="font-size:30px"><span class="ti-arrow-circle-left"></span></a>
    {{uiText[isLang].EditPage}}: {{Page.Langs[isLang]?.Name}}
    <button class="btn" *ngFor="let lang of languages;" [disabled]="lang.code == isLang" (click)="toggleLang(lang.code)"> <span class="flag-icon flag-icon-{{lang.flag}}"></span> </button>
    <div class="float-right">
      <button class="btn" (click)="toggleView()" [disabled]="isShow"><i class="ti-view-list"></i></button> 
      <button class="btn" (click)="toggleView()" [disabled]="!isShow"><i class="ti-layout-grid2"></i></button> 
      <button class="btn" data-toggle="modal" data-target="#myModal" (click)="addPageText()"><i class="ti ti-plus"></i></button>
  </div>
  </h3>
<div *ngIf="Page.PageTextList?.length > 0; else noTextListBlock">
    <div class="card" *ngIf="isShow">
        <div class="card-body">
        <h3>{{uiText[isLang].PageTextSections}}:</h3>
        
          <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let item of Page.PageTextList; let i = index" cdkDrag>
              <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
              <div>    
                  <span class="ti-arrows-vertical mr-2 text-muted"></span>
                  {{item.Langs[isLang]?.Title}}
              </div>
                <div class="float-right">
                  <span class="badge badge-success">Rank: {{item.Rank}}</span>
                  <button class="btn" data-toggle="modal" data-target="#myModal" (click)="editPageText(i)"><i class="ti-pencil"></i></button>
                  <button class="btn" (click)="delPageText(item.TextId)"><i class="ti-trash"></i></button>
                </div>
            </div>
          </div>
        </div>
    </div>
    <div class="card" *ngIf="!isShow">
    <table class="table">
      <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            
            <th>Content</th>
            
            <th>
              <button class="btnr btnr1" data-toggle="modal" data-target="#myModal" (click)="addPageText()"><i class="ti ti-plus"></i></button>
            </th>
          </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of Page.PageTextList; let i = index">
          <td>{{i + 1}}</td>
          <td>{{item.Langs[isLang]?.Title}}</td>
          
          <td [innerHTML]="item.Langs[isLang]?.Content | safe : 'html'"></td>
        
          <td>
              <button class="btn btn-primary" data-toggle="modal" data-target="#myModal" (click)="editPageText(i)"><i class="ti-pencil"></i></button>
              <br>
              <button class="btn btn-danger mt-1" (click)="delPageText(item.TextId)"><i class="ti-trash"></i></button>
          </td>
      </tr>
      </tbody>
    </table>
    </div>
</div>
<ng-template #noTextListBlock><h3>No Text Sections.</h3></ng-template>
</div>
<!--Modal-->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Edit text content 
            <button class="btn" *ngFor="let lang of languages;" [disabled]="lang.code == isLang" (click)="toggleLang(lang.code)"> <span class="flag-icon flag-icon-{{lang.flag}}"></span> </button>
          </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="modalCloseButton">
            <span aria-hidden="true" class="ti-close"></span>
          </button>
        </div>
        <form (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="modal-body">
          <div class="form-group">
            <input type="text"  [(ngModel)]="crtPageText.Langs[isLang].Id" [ngModelOptions]="{standalone: true}">
            PageId: {{crtPageText.PageId}}
            TextId: {{crtPageText.TextId}}
            Lang: {{crtPageText.Langs[isLang].Lang}}
            <label>Title:</label>
            <input type="text" class="form-control" [(ngModel)]="crtPageText.Langs[isLang].Title" [ngModelOptions]="{standalone: true}">
            <label>Text:</label>
            <textarea [ngxSummernote]="summernoteConfig" [(ngModel)]="crtPageText.Langs[isLang].Content" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal"><span class="ti-na"></span> Close</button>
          <button type="submit" class="btn btn-primary"><span class="ti-save"></span> Save</button>
        </div>
      </form>
      </div>
    </div>
  </div>
