<mat-sidenav-container>
  <mat-sidenav #drawer class="sidenav" fixedInViewport 
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === true"
      >
    <mat-toolbar> <img src="/assets/pics/logo-short.png" width="50"> </mat-toolbar>
    <mat-nav-list
    *ngIf="isLoggedIn$ | async">
      <a mat-list-item routerLink="/"><mat-icon>dashboard</mat-icon> Dashboard</a>
      <a mat-list-item routerLink="/pages"><mat-icon>pages</mat-icon> {{ uiText[ isLang ].Pages }} </a>
      <a mat-list-item [matMenuTriggerFor]="menuProducts"><mat-icon>expand_more</mat-icon> {{ uiText[ isLang ].Products }} </a>
      <mat-menu #menuProducts="matMenu" xPosition="before">
        <a mat-menu-item routerLink="/products"><mat-icon>ballot</mat-icon> {{ uiText[ isLang ].ProductsList }} </a>
        <a mat-menu-item routerLink="/product-categories"><mat-icon>storage</mat-icon> {{ uiText[ isLang ].Categories }} </a>
      </mat-menu>
      <hr>
      <a mat-list-item routerLink="emd-users"><mat-icon>people</mat-icon> Users</a>
    </mat-nav-list>

  </mat-sidenav>
  
  <mat-sidenav-content>
    <mat-toolbar color="primary" *ngIf="isLoggedIn$ | async">
      <a
        *ngIf="isLoggedIn$ | async"
        type="button"
        class="btn"
        aria-label="Toggle sidenav"
        (click)="drawer.toggle()"
        >
        <mat-icon>menu</mat-icon>
    </a>
    <a
        *ngIf="isLoggedIn$ | async"
        type="button"
        class="btn"
        aria-label="Toggle sidenav"
        (click)="drawer.toggle()"
        >
      </a>
      <a class="btn btn-warning btn-sm" routerLink="/"><i class="ti-home"></i></a>
      <a class="btn btn-primary btn-sm" routerLink="/pages"><span class="ti-layout"></span> {{ uiText[ isLang ].Pages }} </a>
      <a class="btn btn-success btn-sm" routerLink="/products"><span class="ti-package"></span> {{ uiText[ isLang ].Products }} </a>
        <span class="fill-remaining-space"></span>
      <div>
        <span mat-button [matMenuTriggerFor]="menu">
          <span class="flag-icon flag-icon-{{languages[ crtLang ].flag}}"></span>
        </span>
        <mat-menu #menu="matMenu" class="menu-flag">
          <a mat-menu-item *ngFor="let item of languages; let i = index" routerLink="/" class="menu-flag-item" (click)="changeLang(i)">
            <span class="flag-icon flag-icon-{{item.flag}}"></span>
          </a>
        </mat-menu>
        <a routerLink="/logout" class="btn btn-danger btn-sm" *ngIf="isLoggedIn$ | async">Logout</a>
      </div>
    </mat-toolbar>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>