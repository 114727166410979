import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { SafePipeModule } from 'safe-pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthguardService }  from './service/authguard.service';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyNavComponent } from './my-nav/my-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxSpinnerModule } from "ngx-spinner";

//import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxSummernoteModule } from 'ngx-summernote';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';

import { MatListModule } from '@angular/material/list';
//import { MatGridListModule } from '@angular/material/grid-list';

import { SafePipe } from './pipes/safe.pipe';

import { ToastrModule } from 'ngx-toastr';
import { DragDropModule } from '@angular/cdk/drag-drop';
//import {MatDialogModule} from '@angular/material/dialog';
import swal from 'sweetalert2';
//my components
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PagesComponent } from './comp-pages/pages/pages.component';
import { PageDetailsComponent } from './comp-pages/page-details/page-details.component';
import { ProductsComponent } from './comp-products/products/products.component';
import { ProductDetailsComponent } from './comp-products/product-details/product-details.component';
import { ProductCategoriesComponent } from './comp-products/product-categories/product-categories.component';
import { ProductCategoryDetailsComponent } from './comp-products/product-category-details/product-category-details.component';
import { EmdUsersComponent } from './admin/emd-users/emdUsers/emd-users.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MyNavComponent,
    LoginComponent,
    PagesComponent,
    PageDetailsComponent,
    SafePipe,
    ProductsComponent,
    ProductDetailsComponent,
    ProductCategoriesComponent,
    ProductCategoryDetailsComponent,
    EmdUsersComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    SafePipeModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatMenuModule,
    MatProgressBarModule,
    //MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    //MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    DataTablesModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    DragDropModule
    //MatDialogModule
  ],
  providers: [
    AuthguardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
