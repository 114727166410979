<div class="page-content">
    <h4>{{uiText[isLang].Products}}
      <button class="btn" *ngFor="let lang of languages;" [disabled]="lang.code == isLang" (click)="toggleLang(lang.code)"> <span class="flag-icon flag-icon-{{lang.flag}}"></span> </button>
      <button class="btn btn-success btn-sm float-right mt-2" data-toggle="modal" data-target="#myModal"><i class="ti ti-plus"></i></button>
    </h4>
    <hr>
    <div class="card">
        <div class="card-body">
        <table *ngIf="Products" datatable="ng" [dtOptions]="dtOptions"  class="table table-striped table-bordered">
          <thead>
            <tr>
                <th>#</th>
                <th>Product Id</th>
                <th>Name</th>
                <th>Category</th>
                <th>Active</th>
                <th style="width:50px">@</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let item of Products;let i = index'>
                <td>{{i + 1}}</td>
                <td>{{ item.Id }}</td>
                <td><p *ngIf="item.Langs"> {{ item.Langs[isLang]?.Name }} </p> </td>
                <td [innerHTML]="getCategoryById(item.Category)"></td>
                <td>
                  <mat-slide-toggle 
                    color="primary"
                    [checked]="item.isActive" (click)="toggleActive(item)"></mat-slide-toggle>
                </td>
                <td>
                    <a class="btnr btnr4 btnr-sm" routerLink="/product-details/{{item.Id}}"><i class="ti ti-paint-roller"></i></a>
                </td>
            </tr>
          </tbody>
        </table>
        </div>
    </div>
</div>

<!--Modal-->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Product</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="modalCloseButton">
            <span aria-hidden="true" class="ti-close"></span>
          </button>
        </div>
        <form (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="modal-body">
          <div class="form-group">
            <div *ngFor="let lang of languages;">
            <label><span class="flag-icon flag-icon-{{lang.flag}}"></span> Product Name:</label>
            <input type="text" class="form-control" [(ngModel)]="crtProduct.Langs[ lang.code ].Name" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <hr>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal"><span class="ti-na"></span> Close</button>
          <button type="submit" class="btn btn-primary"><span class="ti-save"></span> Save</button>
        </div>
      </form>
      </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  <p style="color: white">Please Wait. </p>  
</ngx-spinner>
