import { Component } from '@angular/core';
import { GlobalConstants } from '../global-constants';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthguardService } from './../service/authguard.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-my-nav',
  templateUrl: './my-nav.component.html',
  styleUrls: ['./my-nav.component.css']
})
export class MyNavComponent {

  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  
  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthguardService,
    private route: ActivatedRoute,
    private router: Router
    ) {}

  languages = GlobalConstants.languages;
  uiText = GlobalConstants.uiText;
  crtLang = 0;
  isLang = this.languages[ this.crtLang ].code;
  isLoggedIn$: Observable<boolean>;
  

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    if ( !localStorage.getItem('lang') ) {
      localStorage.setItem('lang', JSON.stringify(this.languages[ this.crtLang ] ) );
    }
  } 

  changeLang(i){
    this.crtLang = i;
    localStorage.setItem('lang', JSON.stringify(this.languages[ this.crtLang ] ) );
    this.isLang = this.languages[ this.crtLang ].code;
    //var crtRoute = this.route.snapshot;
    //this.router.navigate([],crtRoute);
    let currentUrl = this.router.url;
    
    this.router.navigateByUrl('/dummy', {skipLocationChange: true}).then(()=>
        this.router.navigate([currentUrl]));
    //window.location.href = window.location.href
  }

}
