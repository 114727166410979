import{ GlobalConstants } from '../global-constants';

export class ProductCategory {
    Id: number;
    Parent: number;
    Rank: number;
    Langs = new Array;
    isActive: boolean;
    
    constructor(){
        var languages = GlobalConstants.languages;
        for(let lang of languages){
        this.Langs[ lang.code ] =  {
            Id: '',
            Name: ''
            }
        }
    }

}