import { Component, OnInit } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Page} from "../../classes/page";
import { DataService } from '../../service/data.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import{ GlobalConstants } from '../../global-constants';

declare var $: any;

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css'],
  providers: [DataService]
})
export class PagesComponent implements OnInit {
  
  constructor(
    private http: Http,
    private dataService:DataService,
    private toastr: ToastrService
    ) {}

  languages = GlobalConstants.languages;
  uiText = GlobalConstants.uiText;
  bootstrapColors = GlobalConstants.bootstrapColors;
  table = "pages";
  Pages: Page[];
  isShow = true;
  isLang;
  
  newPage = new Page();

  modalTitle = "Add New Page";
  items = [];
  cols;
  layoutColumns = [];
  

  ngOnInit() {
    //console.log('Pages start!');
   //this.dataService.getData( this.controller + 'Pages' as string, null ).subscribe( res => { this.Pages = res; } );
   this.isLang = JSON.parse( localStorage.getItem('lang') ).code;
   this.newPage = new Page();
   //debugger;
    this.resetPage();
    $('.modal').appendTo("body");
    
  }

resetPage(){
  //get pages
  this.items = [];
  this.dataService.getData( "Pages/Pages", null ).subscribe( res => { 
    this.Pages = res;
    if( res ){
      for( let item of res ){
        this.items.push( item );
      }
      this.items.sort(function(a, b) {
        return a.Rank - b.Rank;
      });
    }
    this.setLayout();
  });
  /*
  this.dataService.execDataService( "select", this.table, null ).subscribe( res => { 
    this.Pages = res;
    for( let item of res ){
      this.items.push( item );
    }
    this.items.sort(function(a, b) {
      return a.Rank - b.Rank;
    });
    this.setLayout();
  });
  //get pages names
  */
}

editPage(id){
 
 this.newPage = new Page();
 var crtPage = this.Pages.find(x=>x.Id == id);
 this.newPage.Id = id;
  for(let lang of this.languages){
      //debugger;
      if( crtPage.Langs[ lang.code ] ){
      this.newPage.Langs[ lang.code ] = crtPage.Langs[ lang.code ];
      }
  }
 
  //this.newPage.Langs['EN'] = crtPage.Langs['EN'];

 this.modalTitle = "Edit Page Name";
 //console.log(this.newPage);
}

updateBg(item: Page, color){
  item.BootstrapBg = color;
  this.dataService.execDataService( "update", this.table, {Id: item.Id, BootstrapBg: color} ).subscribe(res => {});
}

resetModal(){
  this.newPage = new Page();
  this.modalTitle = "Add New Page";
}

setLayout(){
  //console.log( this.items );
  this.cols = 3;
  for ( let col = 0; col < this.cols; col++ ){
    this.layoutColumns[col] = [];
  }
  if( this.items.length > 0 ){
    var crtCol = 0;
    var maxItem = this.items[ this.items.length - 1 ].Rank;
    var crtIndex = 0;
    for( let i = 0; i <= maxItem; i++ ){
      var crtItem = this.items[ crtIndex ];
    //debugger;
      if( i == crtItem.Rank ){
        this.layoutColumns[ crtCol ].push( crtItem );
        crtIndex++;
      }
      crtCol++;
      crtCol == this.cols ? crtCol = 0 : null;
    }
  }
}
  //update page names
  onSubmit(): void {
    var items = [];
    //for(let [key, item] of this.newPage.Langs){
    for(let lang of this.languages){
      items.push( [{'Id': this.newPage.Langs[ lang.code ].NameId , PageId: this.newPage.Id , 'Lang': lang.code, 'Name': this.newPage.Langs[ lang.code ].Name }] );
      //debugger;
    }
    //debugger;
    if( this.newPage.Id ){
    this.dataService.execDataService( 'update', "page_names", { arrayData: items } ).subscribe( res => { 
        if(res == "ok"){
          this.toastr.success("Page saved","Cool!");
          $('#modalCloseButton').trigger('click');
          this.resetPage();
        }
        //console.log(res);
      });
    }
    else
    {
      //debugger;
      this.dataService.getData( "Pages/AddPage", { arrayData: items } ).subscribe( res => { 
        if(res == "ok"){
          this.toastr.success("Page Created","Cool!");
          $('#modalCloseButton').trigger('click');
          this.resetPage();
        }
      });
    }
  }

  toggleView(){
    this.isShow = !this.isShow;
  }

  toggleLang(lang){
    this.isLang = lang;
  }

  drop2(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }

    var ranks = [];
    var rank = 0;
      for ( let row = 0; row < this.items.length; row++ ){
        for ( let col = 0; col < this.cols; col++ ){
        if( this.layoutColumns[col][row] ){
            //console.log( 'row:' + row + '/col:' + col + ' ... ' + this.layoutColumns[col][row].Name );
            //this.layoutColumns[col][row].Rank = rank;
              ranks.push( [{'Id': this.layoutColumns[col][row].Id , 'Rank': rank }] );
        }
        rank++;
      }
    }
    //console.log( this.items );
    this.dataService.execDataService( 'update', this.table, { arrayData: ranks } ).subscribe( res => {});
    //console.log( this.items );
  }

}
